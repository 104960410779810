import {auth, firebaseApp} from "../components/Firebase";
import {
    onAuthStateChanged,
    signOut,
    updateEmail,
    getAuth,
    reauthenticateWithCredential,
    EmailAuthProvider,
    updatePassword,
    createUserWithEmailAndPassword
} from "firebase/auth";
import {urlFunction} from "./Config";
import {fetchWithAuth, fetchWithAuthNotJSON} from "./Auth";


const createUser = async (email, password, identifiantAffiliation, emailParrain, type,rpva,barreau) => {
    const authCreation = getAuth(firebaseApp);
    return new Promise(function (resolve, reject) {
        createUserWithEmailAndPassword(authCreation, email, password).then((userCredential) => {
            fetch(urlFunction + "/createUser", {
                method: "POST",
                body: JSON.stringify({
                    uid: userCredential.user.uid,
                    email: userCredential.user.email,
                    identifiantAffiliation: identifiantAffiliation,
                    emailParrain: emailParrain,
                    type: type,
                    rpva:rpva,
                    barreau:barreau
                })
            }).then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        resolve(json);
                    });

                }
            })

        })
            .catch((error) => {
                let errorMessage = error.message;
                if (error.message === "Firebase: Error (auth/email-already-in-use).") {
                    errorMessage = "Cet email existe déjà.";
                }
                resolve({error: errorMessage});
            });
    });
}

const updateUser = async (uid, userData) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetchWithAuth(urlFunction + "/updateUser", {
            method: "POST",
            body: JSON.stringify({
                uid,
                userData,
            }),
        });

        resolve(response);
    });
};

const getUserFromUid = async (uid) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!uid) {
                reject(new Error("No uid provided"));
            }
            const response = await fetchWithAuthNotJSON(urlFunction + "/getUser", {
                method: "POST",
                body: JSON.stringify({uid}),
            });

            if (response.ok) {
                const json = await response.json();
                resolve(json);
            } else {
                const errorResponse = await response.json();
                reject(new Error(errorResponse.message || 'Network response was not ok'));
            }
        } catch (error) {
            console.error(`Fetch error: ${error}`);
            reject(new Error(error.message || 'Network response was not ok'));
        }
    });
};

const getUserFromEmail = async (email) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetchWithAuth(urlFunction + "/getUserFromEmail", {
            method: "POST",
            body: JSON.stringify({email}),
        });

        if (response.ok) {
            const json = await response.json();
            resolve(json);
        } else {
            reject('Network response was not ok');
        }
    });
};

const getCurrentUser = async () => {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user);
            } else {
                resolve(null);
            }
        });
    });
};

const disconnect = (url) => {
    localStorage.setItem("isAccessAllowed", "false");
    signOut(auth).then(() => {
        if (url) {
            document.location.href = url;
        } else {
            document.location.href = "/connexion";
        }
    }).catch((error) => {
        console.log("Erreur de deconnexion.");
    });
};

const changeEmailCurrentUser = async (emailNew, password) => {
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            password
        );
        try {
            await reauthenticateWithCredential(auth.currentUser, credential);
            updateEmail(auth.currentUser, emailNew).then(async () => {
                await updateUser(auth.currentUser.uid, {email: emailNew});
                resolve(true);
            }).catch((error) => {
                resolve(false);
            });
        } catch (error) {
            resolve(false);
        }
    });
};

const changePasswordCurrentUser = async (oldPassword, newPassword) => {
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            oldPassword
        );
        try {
            await reauthenticateWithCredential(auth.currentUser, credential);
            updatePassword(auth.currentUser, newPassword).then(() => {
                resolve(true);
            }).catch((error) => {
                resolve(false);
            });
        } catch (error) {
            resolve(false);
        }
    });
};

const isAbonne = async (uid) => {
    const response = await fetchWithAuth(urlFunction + "/getAbonnementFromUid", {
        method: "POST",
        body: JSON.stringify({uid}),
    });

    return response;

};

const addUserAffilie = async (affilie_uid, abonnement_id) => {
    const response = await fetchWithAuth(urlFunction + "/addUserAffilie", {
        method: "POST",
        body: JSON.stringify({
            affilie_uid,
            abonnement_id,
        }),
    });

    if (response.ok) {
        const json = await response.json();
        return json;
    }
};

const removeUserAffilie = async (affilie_uid, abonnement_id) => {
    const response = await fetchWithAuth(urlFunction + "/removeUserAffilie", {
        method: "POST",
        body: JSON.stringify({
            affilie_uid,
            abonnement_id,
        }),
    });

    if (response.ok) {
        const json = await response.json();
        return json;
    }
};


export {
    isAbonne,
    getUserFromUid,
    createUser,
    updateUser,
    getCurrentUser,
    disconnect,
    changeEmailCurrentUser,
    changePasswordCurrentUser,
    getUserFromEmail,
    addUserAffilie,
    removeUserAffilie
};
