import {urlFunction, urlSite, urlSiteAvocat, urlSiteCommissaireDeJustice} from "./Config";
import {getCurrentUser} from "./User";
import {fetchWithAuth} from "./Auth";

const setRequete = async (uid) => {
    const url = `${urlFunction}/setRequete`;
    const options = {
        method: "POST",
        body: JSON.stringify({uid})
    };
    return await fetchWithAuth(url, options);
};

const updateRequete = async (id, requeteData) => {
    const url = `${urlFunction}/updateRequete`;
    const options = {
        method: "POST",
        body: JSON.stringify({id, requeteData})
    };
    return await fetchWithAuth(url, options);
};

const updatePaiement = async (id, data) => {
    const url = `${urlFunction}/updatePaiement`;
    const options = {
        method: "POST",
        body: JSON.stringify({id, data})
    };
    return await fetchWithAuth(url, options);
};

const getRequete = async (id) => {
    const url = `${urlFunction}/getRequete?id=${id}`;
    return await fetchWithAuth(url);
};

const getRequetesFromUserId = async (user_id) => {
    const url = `${urlFunction}/getRequetesFromUserId?userid=${user_id}`;
    return await fetchWithAuth(url);
};

const addFacture = async (requeteId, url, type) => {
    const urlApi = `${urlFunction}/addFacture`;
    const options = {
        method: "POST",
        body: JSON.stringify({requeteId, url, type})
    };
    return await fetchWithAuth(urlApi, options);
};

const addMiseEnDemeure = async (requeteId, url, type) => {
    const urlApi = `${urlFunction}/addMiseEnDemeure`;
    const options = {
        method: "POST",
        body: JSON.stringify({requeteId, url, type})
    };
    return await fetchWithAuth(urlApi, options);
};

const addAccuseReception = async (requeteId, url, type) => {
    const urlApi = `${urlFunction}/addAccuseReception`;
    const options = {
        method: "POST",
        body: JSON.stringify({requeteId, url, type})
    };
    return await fetchWithAuth(urlApi, options);
};

const updateFacture = async (id, requeteData) => {
    const url = `${urlFunction}/updateFacture`;
    const options = {
        method: "POST",
        body: JSON.stringify({id, requeteData})
    };
    return await fetchWithAuth(url, options);
};

const deleteFacture = async (id) => {
    const url = `${urlFunction}/deleteFacture`;
    const options = {
        method: "POST",
        body: JSON.stringify({id})
    };
    return await fetchWithAuth(url, options);
};

const getFacturesFromRequete = async (requeteId) => {
    const url = `${urlFunction}/getFacturesFromRequete?requeteid=${requeteId}`;
    return await fetchWithAuth(url);
};

const iaFacture = async (facture) => {
    let response = await fetch(urlFunction + "/iaFacture?url=" + encodeURIComponent(facture.facture.url) + "&type=" + facture.facture.type);
    if (response.ok) {
        return await response.json();
    }
}

const iaFactureFromURL = async (facture_url, type) => {
    const url = `${urlFunction}/iaFacture?url=${encodeURIComponent(facture_url)}&type=${type}`;
    return await fetchWithAuth(url);
};

const safeParseFloat = (value) => {
    if (isNaN(value) || value === '' || value === null) {
        return 0;
    }
    return parseFloat(value);
};

const getStatut = (numero, type) => {
    switch (numero) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 12:
        case 13:
        case 14:
        case 15:
            return 'En cours';
        case 10:
        case 11:
            return 'Terminé';
        case 16:
            return 'Transmission';
        default:
            return 'En cours';
    }
};

const mf_isactive = async () => {
    const url = `${urlFunction}/mf_isactive`;
    const json = await fetch(url).then((response) => response.json());
    return json.disable_merci_facteur;
};

const getTotal = async (requete_id) => {
    const factures = await getFacturesFromRequete(requete_id);
    if (factures.length > 0) {
        let totalHTLocal = 0;
        let totalTVALocal = 0;
        let totalTTCLocal = 0;
        let totalPercuLocal = 0;
        factures.map((facture) => {
            totalHTLocal += parseFloat(facture.net_amount);
            totalTVALocal += parseFloat(facture.total_tax_amount);
            totalTTCLocal += parseFloat(facture.total_amount);
            totalPercuLocal += parseFloat(facture.total_received);
            return true;
        });
        if (Number.isNaN(totalPercuLocal)) {
            totalPercuLocal = 0;
        }
        await updateRequete(requete_id, {
            totalHT: totalHTLocal,
            totalTVA: totalTVALocal,
            totalTTC: totalTTCLocal,
            totalPercu: totalPercuLocal,
            totalRestant: Math.round((totalTTCLocal - totalPercuLocal) * 100) / 100
        });
        return {
            totalHT: totalHTLocal,
            totalTVA: totalTVALocal,
            totalTTC: totalTTCLocal,
            totalPercu: totalPercuLocal,
            totalRestant: Math.round((totalTTCLocal - totalPercuLocal) * 100) / 100
        };
    }
};

const getTDC = async (codePostalDebiteur) => {
    let response = await fetch(`${urlFunction}/getTDC?codePostalDebiteur=${codePostalDebiteur}`);
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        return false;
    }
}

const getTJ = async (codePostalDebiteur) => {
    const response = await fetch(`${urlFunction}/getTJ?codePostalDebiteur=${codePostalDebiteur}`);

    if (response.ok) {
        const tjs = await response.json();
        return tjs[0]; // retourne le premier TJ correspondant, si disponible
    } else {
        return false;
    }
}


const getVilleFromCodePostal = async (codePostal) => {
    const response = await fetch('/assets/json/laposte_hexasmal.json', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    let ville;

    if (response.ok) {
        const json = await response.json();
        json.map((element) => {
            if (element.fields.code_postal === codePostal) {
                ville = element.fields.nom_de_la_commune;
            }
            return ville;
        });
    }
    return ville;
};

const getRequeteCountFromUserId = async (user_id) => {
    const url = `${urlFunction}/getRequetesFromUserId?userid=${user_id}`;
    const json = await fetchWithAuth(url);
    let en_cours = 0;
    if (json.length > 0) {
        json.map((element) => {
            if (element.etape !== 11 && element.type === "particulier") {
                en_cours++;
            }
            if (element.etape !== 10 && element.type === "entreprise") {
                en_cours++;
            }
            return element;
        });
    }
    return {
        en_cours,
        total: json.length
    };
};

const getTarifEnvoi = async (nbr_pages) => {
    try {
        let response = await fetch(urlFunction + "/mf_get_prix?nbr_pages=" + nbr_pages);
        if (!response.ok) {
            throw new Error('HTTP status ' + response.status);
        }
        let json = await response.json();
        return json;
    } catch (error) {
        return false;
    }
}

const sendRequeteCourrier = async (requeteId, tribunal_nom, tribunal_adresse, tribunal_code_postal, tribunal_ville) => {

    let idEnvoi = await getIdEnvoiFromRequeteId(requeteId, "requete");
    if (idEnvoi.error === "Not found") {
        if (process.env.REACT_APP_TEST_ADRESSE === "TRUE") {
            tribunal_nom = "Raphaël Couloud";
            tribunal_adresse = "26 rue Pierre Laurent";
            tribunal_code_postal = "13006";
            tribunal_ville = "Marseille";
        }
        let response = await fetch(urlFunction + "/mf_send_courrier", {
            method: "POST",
            body: JSON.stringify({
                requeteId: requeteId,
                tribunal_nom: tribunal_nom,
                tribunal_adresse: tribunal_adresse,
                tribunal_code_postal: tribunal_code_postal,
                tribunal_ville: tribunal_ville,
                libelle: "requete"
            })
        });
        if (response.ok) {
            let json = await response.json();
            return json;
        }


    } else {
        return {error: "Courrier déjà envoyé"};
    }


}

const sendMEDCourrier = async (requeteId, nom, adresse, code_postal, ville) => {

    let idEnvoi = await getIdEnvoiFromRequeteId(requeteId, "miseendemeure");
    if (idEnvoi.error === "Not found") {
        let response = await fetch(urlFunction + "/mf_send_courrier", {
            method: "POST",
            body: JSON.stringify({
                requeteId: requeteId,
                tribunal_nom: nom,
                tribunal_adresse: adresse,
                tribunal_code_postal: code_postal,
                tribunal_ville: ville,
                libelle: "miseendemeure"
            })
        });
        if (response.ok) {
            let json = await response.json();
            return json;
        }
    } else {
        return {error: "Courrier déjà envoyé"};
    }


}

const getIdEnvoiFromRequeteId = async (requete_id, type) => {
    const url = `${urlFunction}/getIdEnvoiFromRequeteId?requete_id=${requete_id}&type=${type}`;
    return await fetchWithAuth(url);
};

const getSuiviCourrier = async (idEnvoi) => {
    let response = await fetch(urlFunction + "/mf_suivi?idEnvoi=" + idEnvoi);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}


const getEnvoi = async (idEnvoi) => {
    let response = await fetch(urlFunction + "/mf_get_envoi?idEnvoi=" + idEnvoi);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const getProof = async (refTracking) => {
    let response = await fetch(urlFunction + "/mf_suivi_proof?trackingNumber=" + refTracking);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const choosePM = async (customer) => {
    const url = `${urlFunction}/choosePM`;
    const options = {
        method: "POST",
        body: JSON.stringify({customer})
    };
    return await fetchWithAuth(url, options);
};

const createPayement = async (amount, customer, pm, uid, url, requeteId, libelle) => {
    const urlApi = `${urlFunction}/createPayement`;
    const options = {
        method: "POST",
        body: JSON.stringify({
            amount,
            customer,
            pm,
            uid,
            url,
            requeteId,
            libelle
        })
    };
    return await fetchWithAuth(urlApi, options);
};

const getStatsHome = async () => {
    try {
        let response = await fetch(urlFunction + "/getStatsHome", {
            method: "POST"
        });
        if (response.ok) {
            let json = await response.json();
            return json;
        } else {
            console.error("Response was not OK", response);
        }
    } catch (error) {
        console.error("An error occurred while fetching stats", error);
    }
    return true;
};

const isDebiteurSocieteCivile = async (id) => {
    const requete = await getRequete(id);
    const societesCiviles = [
        {nom: "Société civile immobilière", abbreviation: "SCI"},
        {nom: "Société civile de construction vente", abbreviation: "SCCV"},
        {nom: "Société civile professionnelle", abbreviation: "SCP"},
        {nom: "Société civile de participation", abbreviation: "SCP"},
        {nom: "Société civile de portefeuille", abbreviation: "SC"},
        {nom: "Société civile de moyens", abbreviation: "SCM"},
        {nom: "Société civile coopérative de consommation", abbreviation: "SCC"},
        {nom: "Société civile coopérative", abbreviation: "SCC"},
        {nom: "Société civile coopérative de construction", abbreviation: "SCC"},
        {nom: "Société civile de placement immobilier", abbreviation: "SCPI"},
        {nom: "Société civile d'attribution", abbreviation: "SCA"},
        {nom: "Société civile de placement collectif immobilier", abbreviation: "SCPI"},
        {nom: "Société civile de construction d'immeubles", abbreviation: "SCCI"},
        {nom: "Société civile constituée en vue de la vente d'immeubles", abbreviation: "SCCVI"},
        {nom: "Société civile foncière", abbreviation: "SCF"},
        {nom: "Société civile professionnelle d'architectes", abbreviation: "SCPA"},
        {nom: "Société civile d'intérêt collectif agricole à capital variable", abbreviation: "SCICACV"},
        {nom: "Société civile professionnelle de conseils en propriété industrielle", abbreviation: "SCPCPI"},
        {nom: "Société civile professionnelle d'infirmiers", abbreviation: "SCPI"},
        {nom: "Société civile professionnelle de chirurgiens-dentistes", abbreviation: "SCPCD"},
        {nom: "Société civile professionnelle d'avocats", abbreviation: "SCPA"},
        {
            nom: "Société civile professionnelle titulaire d'un office de commissaire-priseur judiciaire",
            abbreviation: "SCPTOCPJ"
        },
        {nom: "Association", abbreviation: ""},
        {nom: "Association émettant des obligations", abbreviation: ""},
        {nom: "Mutuelle", abbreviation: ""},
        {nom: "Mutuelle agricole", abbreviation: ""},
        {nom: "Société de réassurance mutuelle", abbreviation: ""},
        {nom: "Société d'exercice libéral à forme anonyme", abbreviation: ""},
        {nom: "Société d'exercice libéral à forme anonyme et conseil d'administration", abbreviation: ""},
        {nom: "Société d'exercice libéral à forme anonyme, directoire et conseil de surveillance", abbreviation: ""},
        {nom: "Société d'exercice libéral à responsabilité limitée", abbreviation: ""},
        {nom: "Société d'exercice libéral à responsabilité limitée à associé unique", abbreviation: ""},
        {
            nom: "Société d'exercice libéral à responsabilité limitée à associé unique à capital variable",
            abbreviation: ""
        },
        {nom: "Société d'exercice libéral à responsabilité limitée à capital variable", abbreviation: ""},
        {nom: "Société d'exercice libéral de pharmaciens d'officine à responsabilité limitée", abbreviation: ""},
        {nom: "Société d'exercice libéral de pharmaciens d'officine par actions simplifiée", abbreviation: ""},
        {nom: "Société d'exercice libéral en commandite par actions", abbreviation: ""},
        {nom: "Société d'exercice libéral par actions simplifiée", abbreviation: ""},
        {nom: "Société d'exercice libéral par actions simplifiée à associé unique", abbreviation: ""},
        {
            nom: "Société d'exercice libéral par actions simplifiée à associé unique et capital variable",
            abbreviation: ""
        },
        {nom: "Société d'exercice libéral par actions simplifiée à capital variable", abbreviation: ""},
        {nom: "Syndic de copropriété", abbreviation: ""},
        {nom: "Syndicat", abbreviation: ""}
    ];
    if (requete.formeJuridiqueDebiteur) {
        const societeCivile = societesCiviles.find(societe => requete.formeJuridiqueDebiteur.includes(societe.nom));
        return !!societeCivile;
    } else {
        return false;
    }
};

const isSocieteCivile = (nomSociete) => {
    const societesCiviles = [
        {nom: "Société civile immobilière", abbreviation: "SCI"},
        {nom: "Société civile de construction vente", abbreviation: "SCCV"},
        {nom: "Société civile professionnelle", abbreviation: "SCP"},
        {nom: "Société civile de participation", abbreviation: "SCP"},
        {nom: "Société civile de portefeuille", abbreviation: "SC"},
        {nom: "Société civile de moyens", abbreviation: "SCM"},
        {nom: "Société civile coopérative de consommation", abbreviation: "SCC"},
        {nom: "Société civile coopérative", abbreviation: "SCC"},
        {nom: "Société civile coopérative de construction", abbreviation: "SCC"},
        {nom: "Société civile de placement immobilier", abbreviation: "SCPI"},
        {nom: "Société civile d'attribution", abbreviation: "SCA"},
        {nom: "Société civile de placement collectif immobilier", abbreviation: "SCPI"},
        {nom: "Société civile de construction d'immeubles", abbreviation: "SCCI"},
        {nom: "Société civile constituée en vue de la vente d'immeubles", abbreviation: "SCCVI"},
        {nom: "Société civile foncière", abbreviation: "SCF"},
        {nom: "Société civile professionnelle d'architectes", abbreviation: "SCPA"},
        {nom: "Société civile d'intérêt collectif agricole à capital variable", abbreviation: "SCICACV"},
        {nom: "Société civile professionnelle de conseils en propriété industrielle", abbreviation: "SCPCPI"},
        {nom: "Société civile professionnelle d'infirmiers", abbreviation: "SCPI"},
        {nom: "Société civile professionnelle de chirurgiens-dentistes", abbreviation: "SCPCD"},
        {nom: "Société civile professionnelle d'avocats", abbreviation: "SCPA"},
        {
            nom: "Société civile professionnelle titulaire d'un office de commissaire-priseur judiciaire",
            abbreviation: "SCPTOCPJ"
        },
        {nom: "Association", abbreviation: ""},
        {nom: "Association émettant des obligations", abbreviation: ""},
        {nom: "Mutuelle", abbreviation: ""},
        {nom: "Mutuelle agricole", abbreviation: ""},
        {nom: "Société de réassurance mutuelle", abbreviation: ""},
        {nom: "Société d'exercice libéral à forme anonyme", abbreviation: ""},
        {nom: "Société d'exercice libéral à forme anonyme et conseil d'administration", abbreviation: ""},
        {nom: "Société d'exercice libéral à forme anonyme, directoire et conseil de surveillance", abbreviation: ""},
        {nom: "Société d'exercice libéral à responsabilité limitée", abbreviation: ""},
        {nom: "Société d'exercice libéral à responsabilité limitée à associé unique", abbreviation: ""},
        {
            nom: "Société d'exercice libéral à responsabilité limitée à associé unique à capital variable",
            abbreviation: ""
        },
        {nom: "Société d'exercice libéral à responsabilité limitée à capital variable", abbreviation: ""},
        {nom: "Société d'exercice libéral de pharmaciens d'officine à responsabilité limitée", abbreviation: ""},
        {nom: "Société d'exercice libéral de pharmaciens d'officine par actions simplifiée", abbreviation: ""},
        {nom: "Société d'exercice libéral en commandite par actions", abbreviation: ""},
        {nom: "Société d'exercice libéral par actions simplifiée", abbreviation: ""},
        {nom: "Société d'exercice libéral par actions simplifiée à associé unique", abbreviation: ""},
        {
            nom: "Société d'exercice libéral par actions simplifiée à associé unique et capital variable",
            abbreviation: ""
        },
        {nom: "Société d'exercice libéral par actions simplifiée à capital variable", abbreviation: ""},
        {nom: "Syndic de copropriété", abbreviation: ""},
        {nom: "Syndicat", abbreviation: ""}
    ];
    if (nomSociete) {
        const societeCivile = societesCiviles.find(societe => nomSociete.includes(societe.nom));
        return !!societeCivile;
    } else {
        return false;
    }
};

const convertDemo = async (id) => {
    try {
        const user = await getCurrentUser();
        const url = `${urlFunction}/convertDemo`;
        const options = {
            method: "POST",
            body: JSON.stringify({
                id,
                uid: user.uid
            })
        };
        const response = await fetchWithAuth(url, options);
        return response;
    } catch (error) {
        console.error("Erreur lors de la conversion: ", error);
        throw error;
    }
};

const getRequetesCommissairedeJusticeEnAttente = async () => {
    const url = `${urlFunction}/getRequetesCommissairedeJusticeEnAttente`;
    return await fetchWithAuth(url);
}

const getRequetesAvocatEnAttente = async () => {
    const url = `${urlFunction}/getRequetesAvocatEnAttente`;
    return await fetchWithAuth(url);
}

const accepterProposition = async (requete_id) => {
    const url = `${urlFunction}/getPayementForProposition`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: requete_id,success_url:urlSiteCommissaireDeJustice+'/dashboard/propositions',cancel_url:urlSiteCommissaireDeJustice+'/dashboard/propositions'})
    };
    return await fetchWithAuth(url, options);
    /*const url = `${urlFunction}/accepterProposition`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: requete_id})
    };
    return await fetchWithAuth(url, options);*/

}

const accepterPropositionAvocat = async (requete_id) => {
    const url = `${urlFunction}/getPayementForPropositionAvocat`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: requete_id,success_url:urlSiteAvocat+'/dashboard/mes-dossiers',cancel_url:urlSiteAvocat+'/dashboard/propositions'})
    };
    return await fetchWithAuth(url, options);
}

const validerAchatProposition = async (session_id) => {

    const url = `${urlFunction}/accepterProposition`;
    const options = {
        method: "POST",
        body: JSON.stringify({session_id: session_id})
    };
    return await fetchWithAuth(url, options);

}
const validerAchatPropositionAvocat = async (session_id) => {

    const url = `${urlFunction}/accepterPropositionAvocat`;
    const options = {
        method: "POST",
        body: JSON.stringify({session_id: session_id})
    };
    return await fetchWithAuth(url, options);

}



const refuserProposition = async (requete_id) => {
    const url = `${urlFunction}/refuserProposition`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: requete_id})
    };
    return await fetchWithAuth(url, options);
}
const refuserPropositionAvocat = async (requete_id) => {
    const url = `${urlFunction}/refuserPropositionAvocat`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: requete_id})
    };
    return await fetchWithAuth(url, options);
}



const mesDossiers = async () => {
    const url = `${urlFunction}/mesDossiers`;
    return await fetchWithAuth(url);
}

const mesDossiersAvocat = async () => {
    const url = `${urlFunction}/mesDossiersAvocat`;
    return await fetchWithAuth(url);
}



const terminerDossier = async (id) => {
    const url = `${urlFunction}/terminerDossier`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: id})
    };
    return await fetchWithAuth(url, options);
}
const terminerDossierAvocat = async (id) => {
    const url = `${urlFunction}/terminerDossierAvocat`;
    const options = {
        method: "POST",
        body: JSON.stringify({requete_id: id})
    };
    return await fetchWithAuth(url, options);
}



export {
    terminerDossierAvocat,
    mesDossiersAvocat,
    refuserPropositionAvocat,
    validerAchatProposition,
    terminerDossier,
    mesDossiers,
    refuserProposition,
    accepterProposition,
    getRequetesCommissairedeJusticeEnAttente,
    convertDemo,
    isSocieteCivile,
    isDebiteurSocieteCivile,
    getStatsHome,
    updatePaiement,
    getProof,
    choosePM,
    createPayement,
    getIdEnvoiFromRequeteId,
    getSuiviCourrier,
    sendRequeteCourrier,
    getTarifEnvoi,
    getTDC,
    getStatut,
    setRequete,
    updateRequete,
    getRequete,
    addFacture,
    updateFacture,
    getFacturesFromRequete,
    iaFacture,
    deleteFacture,
    addMiseEnDemeure,
    addAccuseReception,
    getRequetesFromUserId,
    getTotal,
    getVilleFromCodePostal,
    getTJ,
    getRequeteCountFromUserId,
    sendMEDCourrier,
    safeParseFloat,
    getEnvoi,
    mf_isactive,
    iaFactureFromURL,
    getRequetesAvocatEnAttente,
    accepterPropositionAvocat,
    validerAchatPropositionAvocat
};